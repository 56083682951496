import React from 'react';
import styled from 'styled-components';
import { Parallax } from 'react-parallax';
import { Waypoint } from 'react-waypoint';

// import BubblesSort from './mission_bubblessort';

import theme from '../../utils/themeconstants';
import Button from '../../components/elements/button';
import Layout from '../../components/layout';
import Link from '../../components/elements/link';
import SEO from '../../components/seo';
import ContentContainer from '../../components/elements/pageContentContainer';

import hill1 from './images/stage-hill1.svg';
import hill2 from './images/stage-hill2.svg';
import hill3 from './images/stage-hill3.svg';
import hill4 from './images/stage-hill4.svg';


const Gradient = styled.div`
  height: 100vh;
  width: 100%;
  position:fixed;
  top: 0;
  background-image: linear-gradient(to top right, #FD712D, #FFBE1D);
  z-index: -99;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 1s;
`;

const SecondGradient = styled.div`
  height: 100vh;
  width: 100%;
  position:fixed;
  top: 0;
  background-image: linear-gradient(to top right, #003DC0, #46C1ED);
  z-index: -99;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 1s;
`;

const PageContent = styled.div`
  position: relative;
  overflow:hidden;
`;

const Hills = styled.div`
    height: 100vh;
    top: 0;
    width: 100%;
    mix-blend-mode: color-burn;
    position: absolute;
    overflow: hidden;
  .hill{
    position: absolute;
    width: 100vw;
    overflow: visible !important;
  }
  .hill1{
    height: 200px;
    top: 100px;
  }
  .hill2{
    height: 200px;
    top: 10px;
  }
  .hill3{
    height: 200px;
    top: -80px;
  }
  .hill4{
    height: 200px;
    top: 0px;
    opacity: 0.5;
  }
`;

const StageTitle = styled.div`
  height: 80vh;
  display:flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding: ${theme.space.lg}px;
  h1,h2 {
    color: white;
  }
  h2 {
    font-weight: lighter;
  }
`;

const CenteredContainer = styled.div`
  height: ${props => props.height};
  display: flex; 
  flex-direction: ${props => (props.vertical ? 'column' : 'row')};
  justify-content: center;
  align-items: center;
  overflow: visible;
  color: white;
  h1{
    color: white;
    text-align: center;
    @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
      font-size: 1.75em;
      padding: 0 2em;
    }
  }
  p.statement{
    max-width: 540px;
    font-size: 1.25em;
    text-align: center;
    font-weight: lighter;
    padding: 0;
    @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
      font-size: 15px;
      font-weight: normal;
      padding: 0 2em;
    }
  }
`;


class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstGradientVisible: true,
      secondGraidentVisible: true,
    };
  }

  handleFirstWaypointEnter = () => {
    this.setState({
      firstGradientVisible: false,
    });
  }

  handleFirstWaypointLeave = () => {
    this.setState({
      firstGradientVisible: true,
    });
  }

  render() {
    const { firstGradientVisible, secondGraidentVisible } = this.state;

    return (
      <Layout parent="company">
        <SEO title="Tomorrow is a tech startup that develops scalable digital solutions to climate change." keywords={['tomorrow', 'climate impact', 'company', 'startup', 'mission', 'tomorrow app']} />
        <SecondGradient visible={secondGraidentVisible} />
        <Gradient visible={firstGradientVisible} />
        <PageContent>
          <ContentContainer margin="100px auto 0 auto">
            <Hills>
              <Parallax
                className="hill hill1"
                strength={400}
                bgImage={hill1}
              />
              <Parallax
                className="hill hill2"
                strength={350}
                bgImage={hill2}
              />
              <Parallax
                className="hill hill3"
                strength={300}
                bgImage={hill3}
              />
              <Parallax
                className="hill hill4"
                strength={100}
                bgImage={hill4}
              />
            </Hills>
            <StageTitle>
              <h1>Enabling meaningful climate action</h1>
              <h2>Tomorrow builds tech to empower organisations and people to understand and reduce their carbon footprint.</h2>
            </StageTitle>
            {/* <BubblesSort /> */}
            <Waypoint
              bottomOffset="30%"
              topOffset="30%"
              onEnter={this.handleFirstWaypointEnter}
              onLeave={this.handleFirstWaypointLeave}
            >
              <div>
                <CenteredContainer vertical height="80vh">
                  <h1>
                    Climate change is the biggest challenge of our generation
                    {/* https://twitter.com/barackobama/status/514461859542351872 */}
                  </h1>
                  <p className="statement">
                    {'Solving it will cause one of the most profound '}
                    {'societal transformations the world has ever seen. '}
                    {'We can\'t afford to wait any more. '}
                    {'Learn more by reading our '}
                    <Link to="/climatechange">guide</Link>
                    .
                  </p>
                </CenteredContainer>
                <CenteredContainer vertical height="80vh">
                  <h1>Information precedes action</h1>
                  <p className="statement">
                    Anyone should be able to understand the climate impact of their decisions.
                    By empowering people with knowledge, we believe better and more informed decisions will be made.
                  </p>
                </CenteredContainer>
                <CenteredContainer vertical height="80vh">
                  <h1>Together</h1>
                  <p className="statement">
                    Most of our work is{' '} 
                    <Link outward href="https://github.com/tmrowco">open source</Link>
                    , enabling anyone to contribute to our projects and to verify our models.
                    By leveraging a community based on openness, we hope to create a global movement that changes the world.
                  </p>
                </CenteredContainer>
              </div>
            </Waypoint>
            <CenteredContainer vertical height="80vh">
              <div style={{ zIndex: 1 }}>
                <h1 style={{ color: 'white', textAlign: 'center' }}>Inspired?</h1>
                <Button to="/jobs" color="white">
                  Join us!
                </Button>
              </div>
            </CenteredContainer>
          </ContentContainer>
        </PageContent>
      </Layout>
    );
  }
}

export default IndexPage;
